<template>
  <div>
    <b-card title="Edit Persons">
      <div class="d-flex justify-content-center flex-wrap">
        <b-form-group>
          <b-input-group>
            <b-button variant="outline-primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :to="{ name: 'persons', params: { idEvent: body.fkid_mxs_event } }">
              <feather-icon icon="ArrowLeftCircleIcon" class="mr-0 mr-sm-50"></feather-icon>
              Go Back
            </b-button>
          </b-input-group>
        </b-form-group>
      </div>
      <validation-observer ref="simpleRules">
        <b-form>
          <b-tabs content-class="mt-2" pills>
            <b-tab>
              <template #title>
                <feather-icon icon="FileTextIcon" />
                <span>Person Details</span>
              </template>

              <b-row>
                <b-col cols="1"></b-col>
                <b-col>
                  <b-form-group>
                    <h4>Id accred. Doc.</h4>
                    <b-form-input id="input-default" size="lg" v-model="body.pkid_accreditation_document_detail"
                      plaintext />
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group>
                    <h4>Accred. Process Type</h4>
                    <b-form-input id="input-default" size="lg" v-model="body.fkid_accreditation_process_type"
                      :plaintext="togglePlaintext" plaintext />
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group>
                    <h4>Id Event</h4>
                    <b-form-input id="input-default" size="lg" v-model="body.fkid_mxs_event" :plaintext="togglePlaintext"
                      plaintext />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="1"></b-col>
                <b-col>
                  <b-form-group>
                    <h4>Season</h4>
                    <b-form-input id="input-default" size="lg" v-model="body.Temporada[0]" :plaintext="togglePlaintext"
                      plaintext />
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group>
                    <h4>Aforo Name</h4>
                    <b-form-input id="input-default" size="lg" v-model="body.Aforo_name" :plaintext="togglePlaintext"
                      plaintext />
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group>
                    <h4>Event Name</h4>
                    <b-form-input id="input-default" size="lg" v-model="body.event_name" :plaintext="togglePlaintext"
                      plaintext />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="1"></b-col>
                <b-col>
                  <b-form-group>
                    <h4>Accred. localitzador</h4>
                    <b-form-input id="input-default" size="lg" v-model="body.accreditation_localitzador[0]"
                      :plaintext="togglePlaintext" plaintext />
                  </b-form-group>
                </b-col>
                <b-col cols="1"></b-col>
                <b-col>
                  <b-form-group>
                    <h4>Mètode Creació</h4>
                    <b-form-input id="input-default" size="lg" v-model="body.metode_creacio" :plaintext="togglePlaintext"
                      plaintext />
                  </b-form-group>
                </b-col>
                <b-col cols="1"></b-col>
              </b-row>

              <b-row>
                <b-col cols="1"></b-col>
                <b-col>
                  <b-form-group>
                    <validation-provider #default="{ errors }" name="Name" rules="required">
                      <h4>Name</h4>
                      <b-form-input id="input-default" size="lg" v-model="body.Nom_titular" :plaintext="togglePlaintext"
                        required :state="errors.length > 0 ? false : null" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="1"></b-col>
                <b-col>
                  <b-form-group>
                    <validation-provider #default="{ errors }" name="Surname1" rules="required">
                      <h4>Surname1</h4>
                      <b-form-input id="input-default" size="lg" v-model="body.Cognom1_titular"
                        :plaintext="togglePlaintext" required :state="errors.length > 0 ? false : null" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="1"></b-col>
              </b-row>

              <b-row>
                <b-col cols="1"></b-col>
                <b-col>
                  <b-form-group>
                    <h4>Surname2</h4>
                    <b-form-input id="input-default" size="lg" v-model="body.Cognom2_titular"
                      :plaintext="togglePlaintext" />
                  </b-form-group>
                </b-col>
                <b-col cols="1"></b-col>
                <b-col>
                  <b-form-group>
                    <h4>DOI</h4>
                    <b-form-select size="lg" v-model="body.DOI_Type" :options="dniOptions" :disabled="dateDisabled" />
                    <validation-provider #default="{ errors }" name="DOI" rules="required|dni">
                      <b-form-input v-if="body.DOI_Type === 'DNI'" id="input-default" size="lg" maxlength="9"
                        v-model="body.DNI" :plaintext="togglePlaintext" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    <validation-provider v-if="body.DOI_Type === 'Other'" #default="{ errors }" name="DOI"
                      rules="required">
                      <b-form-input size="lg" :plaintext="togglePlaintext" v-model="body.DNI" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="1"></b-col>
              </b-row>

              <b-row>
                <b-col cols="1"></b-col>
                <b-col>
                  <b-form-group>
                    <h4>Car Plate</h4>
                    <b-form-input id="input-default" size="lg" v-model="body.Matricula" :plaintext="togglePlaintext" />
                  </b-form-group>
                </b-col>
                <b-col cols="1"></b-col>
                <b-col>
                  <b-form-group>
                    <h4>SCA Acces</h4>
                    <b-form-select size="lg" v-model="body.AccesSCA" :options="dooraccesoptions"
                      :disabled="dateDisabled" />
                  </b-form-group>
                </b-col>
                <b-col cols="1"></b-col>
              </b-row>

              <b-row>
                <b-col cols="1"></b-col>
                <b-col>
                  <b-form-group>
                    <h4>Doc Status</h4>
                    <b-form-select size="lg" v-model="docStatusSelected" :options="docStatusOptions"
                      @change="configureDocStatus()" :disabled="dateDisabled" />
                  </b-form-group>
                </b-col>
                <b-col cols="1"></b-col>
                <b-col>
                  <b-form-group>
                    <h4>Company Name</h4>
                    {{ body.Empresa_nom }}
                    <b-form-select size="lg" v-model="companySeleceted" :options="companies" @change="configureComany()"
                      :disabled="dateDisabled" />
                  </b-form-group>
                </b-col>
                <b-col cols="1"></b-col>
              </b-row>

              <b-row>
                <b-col cols="1"></b-col>
                <b-col>
                  <b-form-group>
                    <h4>Doc Status SCA</h4>
                    <b-form-input id="input-default" size="lg" v-model="body.DocStatusSCA" :plaintext="togglePlaintext"
                      plaintext />
                  </b-form-group>
                </b-col>
                <b-col cols="1"></b-col>
                <b-col>
                  <b-form-group>
                    <h4>Barcode (Wristband)</h4>
                    <b-form-input id="input-default" size="lg" v-model="body.Cod_Barras" :plaintext="togglePlaintext" />
                  </b-form-group>
                </b-col>
                <b-col cols="1"></b-col>
              </b-row>

              <b-row>
                <b-col cols="1"></b-col>
                <b-col>
                  <b-form-group>
                    <h4>Email</h4>
                    <b-form-input id="input-default" size="lg" v-model="body.email" :plaintext="togglePlaintext" />
                  </b-form-group>
                </b-col>
                <b-col cols="1"></b-col>
                <b-col>
                  <b-form-group>
                    <h4>Data Naixement</h4>
                    <flat-pickr v-model="body.Data_neixament" class="form-control" :disabled="dateDisabled" />
                  </b-form-group>
                </b-col>
                <b-col cols="1"></b-col>
              </b-row>

              <b-row>
                <b-col cols="1"></b-col>
                <b-col>
                  <b-form-group>
                    <h4>Upload FileName</h4>
                    <b-form-input id="input-default" size="lg" v-model="body.UploadFilename" :plaintext="togglePlaintext"
                      plaintext />
                  </b-form-group>
                </b-col>
                <b-col cols="1"></b-col>
                <b-col>
                  <b-form-group>
                    <h4>Id Usuari Modificació</h4>
                    <b-form-input id="input-default" size="lg" v-model="body.ID_usuari_modificacio"
                      :plaintext="togglePlaintext" plaintext />
                  </b-form-group>
                </b-col>
                <b-col cols="1"></b-col>
              </b-row>

              <b-row class="justify-content-center">
                <b-col cols="1"></b-col>
                <b-col>
                  <b-form-group>
                    <h4>TimeStamp Creació</h4>
                    <b-form-input id="input-default" size="lg" v-model="body.timestamp_creacio"
                      :plaintext="togglePlaintext" plaintext />
                  </b-form-group>
                </b-col>
                <b-col cols="1"></b-col>
                <b-col>
                  <b-form-group>
                    <h4>TiemeStamp Modificació</h4>
                    <b-form-input id="input-default" size="lg" v-model="body.timestamp_modificacio"
                      :plaintext="togglePlaintext" plaintext />
                  </b-form-group>
                </b-col>
                <b-col cols="1"></b-col>
              </b-row>

              <b-row class="justify-content-center">
                <b-button variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1" @click.prevent="validationForm"
                  :hidden="dateDisabled" type="submit">
                  <feather-icon icon="SaveIcon" class="mr-0 mr-sm-50"></feather-icon>
                  Update Person
                </b-button>
                <b-button variant="outline-primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  :to="{ name: 'persons', params: { idEvent: body.fkid_mxs_event } }">
                  <feather-icon icon="ArrowLeftCircleIcon" class="mr-0 mr-sm-50"></feather-icon>
                  Go Back
                </b-button>
              </b-row>

            </b-tab>
            <b-tab>
              <template #title>
                <feather-icon icon="LogInIcon" />
                <span>Accred. Acces</span>
              </template>

              <b-table striped hover responsive class="position-relative" :per-page="perPage" :current-page="currentPage"
                :items="items" :fields="fields">
                <template #cell(StatusDescription)="data">
                  <b-badge :variant="statusVariant(data.item.StatusDescription)">
                    {{ data.item.StatusDescription }}
                  </b-badge>
                </template>
                <template #cell(StatusDescription_Offline)="data">
                  <b-badge :variant="statusVariant(data.item.StatusDescription_Offline)">
                    {{ data.item.StatusDescription_Offline }}
                  </b-badge>
                </template>
              </b-table>

              <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
                <!-- page length -->
                <b-form-group label="Per Page" label-cols="6" label-align="left" label-size="sm" label-for="sortBySelect"
                  class="text-nowrap mb-md-0 mr-1">
                  <b-form-select id="perPageSelect" v-model="perPage" size="sm" inline :options="pageOptions" />
                </b-form-group>

                <!-- pagination -->
                <div>
                  <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number
                    prev-class="prev-item" next-class="next-item" class="mb-0">
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </b-card-body>

            </b-tab>
          </b-tabs>
        </b-form>
      </validation-observer>

    </b-card>
  </div>
</template>

<script>
import { BTable, BCard, BCardText, BTabs, BTab, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BInputGroup, BInputGroupPrepend, BRow, BCol, BFormSelect, BCardBody, BPagination, BBadge, BFormInvalidFeedback, BFormValidFeedback } from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import router from "@/router";
import { ref } from "@vue/composition-api";
import axios from "@/libs/axios";
import { extend } from 'vee-validate';
import { regex } from 'vee-validate/dist/rules';

extend('dni', {
  ...regex,
  params: ['lazy'],
  validate: value => {
    if (/^(\d{8})([A-Z])$/.test(value)) {
      if ('TRWAGMYFPDXBNJZSQVHLCKE'.charAt(parseInt(value, 10) % 23) === value.charAt(8)) {
        return true;
      }
      return 'The DOI letter is incorrect';
    }
    return 'The DOI is not valid';
  }
});

export default {
  components: {
    BTable, BCard, BCardText, BTabs, BTab, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BInputGroup, BInputGroupPrepend, BRow, BCol, BFormSelect, BCardBody, BPagination, BBadge, BFormInvalidFeedback, BFormValidFeedback, ValidationProvider, ValidationObserver,

    flatPickr,
  },
  computed: {
    statusVariant() {
      const statusColor = {
        OK: 'light-success',
        OK_Exit: 'light-warning',
        'OK_Exit+': 'light-warning',
        OK_entered: 'light-info',
        ERROR: 'light-danger',
        'INCORRECTE No es vàlid realitzar moviment de sortida': 'light-danger',
        REPETIT: 'light-danger',
        'Data venciment fora termini': 'light-danger',
        'Data fi validesa fora termini': 'light-danger',
        'Data caducitat fora termini': 'light-danger',
        'No existeix document en detall event': 'light-danger',
        'A BAIXA Entrada a Baixa': 'light-danger',
        'ERROR DNI ACREDITACIO duplicat': 'light-danger',
        'ACREDITACIO Anulada': 'light-danger',
        'DENEGEAT ACREDITACIO': 'light-danger',
      }
      return status => statusColor[status]
    },
    docStatusOptions() {
      this.docStatusSelected =
        this.docStatus = this.docStatus.map((t) => ({ text: t, value: t }))
      return this.docStatus;
    },
    configureDocStatus() {
      const docStatus = this.docStatusSelected.split(" - ");
      this.body.fkid_accreditation_status = docStatus[0]
      this.body.DocStatus = docStatus[1];
    },
    dooraccesoptions() {
      this.dooraccesSelected =
        this.dooracces = this.dooracces.map((t) => ({ text: t, value: t }))
      return this.dooracces;
    },
    configureComany() {
      const company = this.companySeleceted.split(" - ");
      console.log(company[1])
      const idCompany = company[1].replace(/[()]/g, "")
      this.body.fkid_accreditation_company = idCompany;
      this.body.Empresa_nom = company[0];
    },
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.message();
        }
      })
    },
    async message() {
      const error = await this.onSubmit();
      if (error[0]) {
        const variant = "danger";
        this.$bvToast.toast("" + error[1], {
          title: "Failed",
          variant,
          solid: true,
        });
      } else if (!error[0]) {
        this.togglePlaintext = true;
        this.dateDisabled = true;
        const variant = "success";
        this.$bvToast.toast("Person updated correctly", {
          title: `Update`,
          variant,
          solid: true,
        });
      }
    },
    dateFormat(date) {
      const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      const minutesArray = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"]
      let normalDate = date.split("T").join(" ")
      normalDate = date.split("Z").join("")
      const d = new Date(normalDate);
      let minutes = d.getMinutes();
      if (minutesArray.includes(d.getMinutes().toString())) {
        minutes = '0' + d.getMinutes();
      }
      const finalDate = d.getDate() + ' ' + months[d.getMonth()] + ' ' + d.getFullYear() + ' ' + d.getHours() + ':' + minutes;
      return finalDate;
    },
  },
  data() {
    return {
      dni: '',
      required,
      dateDisabled: false,
      togglePlaintext: false,
      docStatus: [],
      docStatusSelected: "",
      companySeleceted: "",
      dniOptions: [
        { text: 'DNI', value: 'DNI' },
        { text: 'Other', value: 'Other' },
      ],
      perPage: 10,
      pageOptions: [5, 10, 20, 50],
      totalRows: 1,
      currentPage: 1,
      fields: [
        { key: "pkid_accreditation_sca_registry", label: "Id" },
        { key: "timestamp_creacio", label: "Creacio" },
        { key: "timestamp_pda", label: "PDA Time" },
        { key: "source", label: "Source." },
        { key: "ID_user", label: "ID User" },
        { key: "way", label: "Way" },
        { key: "DoorName", label: "Door Name" },
        { key: "fkid_accreditation", label: "Accred." },
        { key: "fkid_mxs_event", label: "Event" },
        { key: "StatusDescription", label: "Status" },
        { key: "StatusDescription_Offline", label: "Status Offline" },
      ],
      items: [],
      dooracces: [],
      companies: [],
    };
  },
  async created() {

    await axios
      .get("/company")
      .then((results) => {
        results.data.forEach(element => {
          this.companies.push(element.Name + " - (" + element.pkid_accreditation_company + ")")
        });
      })
      .catch((error) => {
        const variant = "danger";
        this.$bvToast.toast("Failed to connect", {
          title: `` + error,
          variant,
          solid: true,
        });
      });

    await axios
      .get("/docStatus")
      .then((results) => {
        results.data.forEach(element => {
          this.docStatus.push(element.pkid_accreditation_status + " - " + element.Description)
        });
      })
      .catch((error) => {
        const variant = "danger";
        this.$bvToast.toast("Failed to connect", {
          title: `` + error,
          variant,
          solid: true,
        });
      })

    await axios
      .get("/accred-doc-detailed?pkid_accreditation_document_detail=" + router.currentRoute.params.id)
      .then((results) => {
        this.body = results.data[0];
        this.docStatusSelected = this.body.fkid_accreditation_status + " - " + this.body.DocStatus
        this.companySeleceted = this.body.Empresa_nom + " - (" + this.body.fkid_accreditation_company + ")"

        const timecreate = this.body.timestamp_creacio;
        const timeModifiyed = this.body.timestamp_modificacio;

        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const c = new Date(timecreate);
        const m = new Date(timeModifiyed);
        let cminutes = c.getMinutes();
        if (c.getMinutes() === 0) {
          cminutes = '00'
        }
        let mminutes = m.getMinutes();
        if (c.getMinutes() === 0) {
          mminutes = '00'
        }
        const creacio = c.getDate() + ' ' + months[c.getMonth()] + ' ' + c.getFullYear() + ' ' + (c.getHours() - 1) + ':' + cminutes;
        const modificacio = m.getDate() + ' ' + months[m.getMonth()] + ' ' + m.getFullYear() + ' ' + (m.getHours() - 1) + ':' + mminutes;
        this.body.timestamp_creacio = creacio;
        this.body.timestamp_modificacio = modificacio;

      })
      .catch((error) => {
        const variant = "danger";
        this.$bvToast.toast("Failed to connect", {
          title: `` + error,
          variant,
          solid: true,
        });
      })

    await axios
      .get("/accred-sca-registry?fkid_accreditation=" + router.currentRoute.params.id)
      .then((results) => {
        this.items = results.data;
        this.totalRows = this.items.length;
      })
      .catch((error) => {
        const variant = "danger";
        this.$bvToast.toast("Failed to connect", {
          title: `` + error,
          variant,
          solid: true,
        });
      })

    await axios
      .get("/dooracces?ID_Aforo=" + this.body.ID_Aforo)
      .then((results) => {
        results.data.forEach(element => {
          this.dooracces.push(element.AccesSCA)
        });
      })
      .catch((error) => {
        const variant = "danger";
        this.$bvToast.toast("Failed to connect", {
          title: `` + error,
          variant,
          solid: true,
        });
      })

  },
  mounted() {
    if (localStorage.getItem("accessToken") === null) {
      this.$router.go(0)
    }
  },
  setup() {
    const blankPersonData = {
      pkid_accreditation_document_detail: "",
      Temporada: "",
      accreditation_localitzador: "",
      metode_creacio: "",
      fkid_accreditation_process_type: "",
      fkid_mxs_event: "",
      event_name: "",
      Aforo_name: "",
      email: "",
      Matricula: "",
      ID_usuari_modificacio: "",
      UploadFilename: "",
      Data_neixament: "",
      timestamp_creacio: "",
      timestamp_modificacio: "",
    };

    const body = ref(JSON.parse(JSON.stringify(blankPersonData)));

    const onSubmit = async () => {
      const onError = [false, ""];

      for (let atr in body.value) {
        if (body.value[atr] === null) {
          body.value[atr] = ""
        }
      }

      await axios
        .put("/accred-doc-detailed", body.value)
        .then((result) => {
          onError[0] = false;
        })
        .catch((error) => {
          onError[0] = true;
          onError[1] = error;
        });
      return onError;
    };


    return {
      body,
      onSubmit,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
